.certificateServices {
  height: 100vh;
  overflow-y: scroll;
}
.certificateServices .top-img {
  height: 24.6875rem;
  background-image: url("../../../assets/loginIndex/humanTopBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.certificateServices .block-item1 {
  height: 32.8125rem;
  background-color: white;
}
.certificateServices .block-item1 .block-item1-wrap {
  width: 73rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.certificateServices .block-item1 .block-item1-wrap .left {
  padding-left: 1.0625rem;
}
.certificateServices .block-item1 .block-item1-wrap .left .title {
  font-size: 1.4375rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: .625rem;
  text-align: left;
}
.certificateServices .block-item1 .block-item1-wrap .left .detail {
  font-size: .9375rem;
  font-weight: 400;
  color: #666666;
  width: 21.5625rem;
  text-align: left;
  line-height: 1.4375rem;
}
.certificateServices .block-item1 .block-item1-wrap .right {
  height: 19.0625rem;
  width: 29.4375rem;
  background-image: url("../../../assets/loginIndex/humanImg1.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.certificateServices .block-item2 {
  height: 32.8125rem;
  background-color: #F9F9F9;
}
.certificateServices .block-item2 .block-item2-wrap {
  width: 73rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.certificateServices .block-item2 .block-item2-wrap .right {
  text-align: right;
  padding-right: 1.0625rem;
}
.certificateServices .block-item2 .block-item2-wrap .right .title {
  font-size: 1.4375rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: .625rem;
  text-align: left;
}
.certificateServices .block-item2 .block-item2-wrap .right .detail {
  font-size: .9375rem;
  font-weight: 400;
  color: #666666;
  width: 21.5625rem;
  text-align: left;
  line-height: 1.4375rem;
}
.certificateServices .block-item2 .block-item2-wrap .left {
  height: 19.0625rem;
  width: 29.4375rem;
  background-image: url("../../../assets/loginIndex/humanImg2.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.certificateServices .block-item3 {
  height: 32.8125rem;
  background-color: white;
}
.certificateServices .block-item3 .block-item3-wrap {
  width: 73rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.certificateServices .block-item3 .block-item3-wrap .left {
  padding-left: 1.0625rem;
}
.certificateServices .block-item3 .block-item3-wrap .left .title {
  font-size: 1.4375rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: .625rem;
  text-align: left;
}
.certificateServices .block-item3 .block-item3-wrap .left .detail {
  font-size: .9375rem;
  font-weight: 400;
  color: #666666;
  width: 21.5625rem;
  text-align: left;
  line-height: 1.4375rem;
}
.certificateServices .block-item3 .block-item3-wrap .right {
  height: 19.0625rem;
  width: 29.4375rem;
  background-image: url("../../../assets/loginIndex/humanImg3.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.certificateServices .block-item4 {
  height: 32.8125rem;
  background-color: #F9F9F9;
}
.certificateServices .block-item4 .block-item4-wrap {
  width: 73rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.certificateServices .block-item4 .block-item4-wrap .right {
  text-align: right;
  padding-right: 1.0625rem;
}
.certificateServices .block-item4 .block-item4-wrap .right .title {
  font-size: 1.4375rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: .625rem;
  text-align: left;
}
.certificateServices .block-item4 .block-item4-wrap .right .detail {
  font-size: .9375rem;
  font-weight: 400;
  color: #666666;
  width: 21.5625rem;
  text-align: left;
  line-height: 1.4375rem;
}
.certificateServices .block-item4 .block-item4-wrap .left {
  height: 19.0625rem;
  width: 29.4375rem;
  background-image: url("../../../assets/loginIndex/humanImg4.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.certificateServices .bottom-img {
  height: 23.4375rem;
  background-image: url("../../../assets/loginIndex/humanBottomBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.certificateServices .bottom-img h4 {
  color: #fff;
  text-align: center;
  padding-top: 7.5rem;
  font-size: 2.8125rem;
}
.certificateServices .bottom-img .btn {
  padding-top: 2.8125rem;
  display: flex;
  justify-content: center;
}
.certificateServices .dialog-footer .el-button:nth-child(2) {
  background: #2B77FD;
}
.certificateServices /deep/ .el-dialog__wrapper {
  z-index: 9999 !important;
}
.certificateServices /deep/ .el-dialog {
  background-color: transparent;
  border-radius: 20px;
}
.certificateServices /deep/ .el-dialog__header {
  background: #fff;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  padding: 20px 20px 10px;
}
.certificateServices /deep/ .el-dialog__header .el-dialog__title {
  color: #333;
  font-size: 20px;
}
.certificateServices /deep/ .el-dialog__header .el-dialog__headerbtn {
  top: 38px;
}
.certificateServices /deep/ .el-dialog__header .el-dialog__headerbtn .el-dialog__close {
  color: #333;
}
.certificateServices /deep/ .el-dialog__header .el-dialog__headerbtn:focus .el-dialog__close,
.certificateServices /deep/ .el-dialog__header .el-dialog__headerbtn:hover .el-dialog__close {
  color: #333;
}
.certificateServices /deep/ .el-dialog__body {
  background: #fff;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  padding: 0 30px;
}
.certificateServices .contactTeacherPopup {
  padding-bottom: 57px;
}
.certificateServices .contactTeacherPopup .dialogTop {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 45px;
}
.certificateServices .contactTeacherPopup .dialogTop span {
  font-size: 23px;
  color: #333;
}
